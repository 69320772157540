<template>
    <v-container>
        <PopGallery />
    </v-container>
</template>

<script>
import PopGallery from '@/components/pop/Index'

export default {
    components: {
        PopGallery
    }
}
</script>